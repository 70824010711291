import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IBanner } from '../interfaces/banner.interfaces';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  private urlApi = environment.urlApi

  constructor(
    private httpClient: HttpClient
  ) { }

  getActive(){
    return this.httpClient.get<IBanner>(`${this.urlApi}/banner/activos`).pipe(
      catchError(e => {
        return throwError(e)
      })
    )
  }
}
