
<div class="fixed-bottom fixed-bottom-player">
  <div class="float-right">

    <nav class="navbar-expand navbar-dark bg-dark nav-player pt-3 pl-4"
         style="border-top-left-radius: 10px;"
         *ngIf="flagBanner == true">
      <div class="collapse navbar-collapse show" id="navbarCollapse">
        <img class="albumart-player animated fadeIn player-banner" src="{{ urlImage }}{{ imgBannerAlbumart }}" *ngIf="imgBannerAlbumart != null">
      </div>
    </nav>

    <!-- style="border-top-left-radius: 10px;" -->
    <nav class="navbar-expand navbar-dark bg-dark nav-player pt-3 pl-4">
      <div class="collapse navbar-collapse show" id="navbarCollapse">
        <ul class="navbar-nav mr-auto nav-player-ul">
          <li class="nav-item active mt-2 btnPlay">
            <span class="nav-link pt-20">
              <i *ngIf="playFlag" class="fa fa-pause fa-2x animated rubberBand delay-1s" (click)="toggleAudio()"></i>
              <i *ngIf="!playFlag" class="fa fa-play fa-2x animated rubberBand delay-1s" (click)="toggleAudio()"></i>
            </span>
          </li>
          <li class="nav-item pl-3 text-center infoSong">
            <span class="nav-link label-player" *ngIf="replysong != null">
              {{ auxArtista }}
              <br>
              {{ auxSong }}
            </span>
          </li>
          <li class="nav-item pl-3 playerAlbumart" *ngIf="replyAlbumart != null">
            <img class="albumart-player animated bounceIn delay-3s" src="{{ replyAlbumart['albumart'] }}" alt="albumart" width="60">
          </li>
        </ul>
      </div>
    </nav>

  </div>

</div>
