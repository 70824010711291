import { Component, OnInit, ɵConsole } from '@angular/core';
import { ReplySong, ReplyAlbumart } from '../../interfaces/music.interface';
import { SongService } from '../../services/song.service';
import { interval } from 'rxjs';
import { BannerService } from '../../services/banner.service';
import { IBanner, Banner } from '../../interfaces/banner.interfaces';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {

  audio = new Audio();
  playFlag: boolean = false;

  iBanner: IBanner = null
  banner: Banner[] = null

  imgBannerAlbumart: String = null
  urlImage = environment.urlApi + "/view/image/banner/name/"

  replysong: ReplySong = null
  replyAlbumart: ReplyAlbumart = null
  auxSong:String = null;
  auxArtista:String = null;

  flagBanner: boolean = false

  constructor(
    private _songService:SongService,
    private bannerService: BannerService
  ) { }

  ngOnInit() {
    this.songCurrent()
    this.albumart()

    this.bannerActive()

    const seconds = interval(90000)
    seconds.subscribe( respCurrentSong => {

      this.songCurrent();
      this.albumart();
    })

  }

  songCurrent(){
    this._songService.getSongCurrent().subscribe(resp => {
      this.replysong = resp

      if(this.flagBanner == true){
        this.cutSong(resp.artista, resp.cancion, 17)
      }else{
        this.cutSong(resp.artista, resp.cancion, 25)
      }

    })
  }

  albumart(){
    this._songService.getAlbumart().subscribe(resp => {
      this.replyAlbumart = resp
    })
  }


  playAudio() {
    this.audio.src = "https://iannes.mi-radio.stream:5003/stream";
    this.audio.load();
    this.audio.play();
    this.playFlag = true;
  }

  pauseAudio() {
    this.audio.pause();
    this.playFlag = false;
  }

  toggleAudio(){
    if(this.playFlag == true){
      this.pauseAudio();
    }else{
      this.playAudio();
    }
  }

  bannerActive(){
    this.bannerService.getActive().subscribe(resp => {
      this.iBanner = resp
      this.banner = resp.banner

      if(this.iBanner.elementos == 2){
        this.flagBanner = true

        if(this.replysong != null){

          this.cutSong(this.replysong.artista, this.replysong.cancion, 10)
        }

        setTimeout(() => {
          this.imgBannerAlbumart = this.banner[0].imagen

          setTimeout(() => {
            this.imgBannerAlbumart = this.banner[1].imagen

            setTimeout(() => {
              this.flagBanner = false

              if(this.replysong.artista.length > 20 || this.replysong.cancion.length > 20 ){
                this.cutSong(this.replysong.artista, this.replysong.cancion, 20)
              }

              setTimeout(() => {
                this.bannerActive()
              }, 300000);

            }, 10000);

          }, 10000);

        }, 2000);

      }else if(this.iBanner.elementos == 1){

        this.flagBanner = true

        if(this.replysong != null){
          this.cutSong(this.replysong.artista, this.replysong.cancion, 10)
        }

        setTimeout(() => {
          this.imgBannerAlbumart = this.banner[0].imagen

          setTimeout(() => {
            this.flagBanner = false

            if(this.replysong.artista.length > 20 || this.replysong.cancion.length > 20 ){
              this.cutSong(this.replysong.artista, this.replysong.cancion, 20)
            }

            setTimeout(() => {
              this.bannerActive()
            }, 300000);

          }, 10000);

        }, 2000);

      }

    })
  }

  cutSong(textArtista: string, textSong: string, length: number){

    if(length <= textArtista.length){
      this.auxArtista = textArtista.substring(0, length) + "..."
    }else{
      this.auxArtista = textArtista
    }

    if(length <= textSong.length){
      this.auxSong = textSong.substring(0, length) + "..."
    }else{
      this.auxSong = textSong
    }




  }

}
