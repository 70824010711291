import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ReplySong, ReplyAlbumart } from '../interfaces/music.interface';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SongService {

  private urlApi = environment.urlApi

  constructor(
    private _httpClient: HttpClient
  ) { }


  getSongCurrent(){
    return this._httpClient.get<ReplySong>(`${this.urlApi}/cancion/actual`).pipe(
      catchError(e => {
        return throwError(e)
      })
    )
  }

  getAlbumart(){
    return this._httpClient.get<ReplyAlbumart>(`${this.urlApi}/cancion/albumart/60`).pipe(
      catchError(e => {
        return throwError(e)
      })
    )
  }
}
